@include dark-mode () {
  :root {

    // Custom variable values only support SassScript inside `#{}`.
    @each $color,
    $value in $colors-alt {
      --#{$color}: #{$value};
    }

    @each $color,
    $value in $theme-colors-alt {
      --#{$color}: #{$value};
    }
  }
}

//